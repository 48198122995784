'use strict';

var $ = require('jquery');

function LimitsifyAPI() {
  var config = {
    apiBaseUrl: 'https://reginapps.com/limitsify/api',
    apiToken: ''
  };

  var MCO = {};

  var buildUrl = function(endpoint) {
    return [config.apiBaseUrl, endpoint].join('/');
  };

  var makeRequest = function(url, data, resolve, reject) {
    const json = JSON.stringify(data);
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, [false]);
    xhr.setRequestHeader('Authorization', 'Bearer: ' + config.apiToken);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'json';
    xhr.send(json);
    xhr.onload = function() {
      if (xhr.status == 200) {
        resolve(xhr.response);
      } else {
        reject(xhr);
      }
    };
    xhr.onerror = function () {
      reject();
    }
  };

  MCO.init = function(config) {
    MCO.configure(config);
    return MCO;
  };

  MCO.configure = function(newConfig) {
    config['apiToken'] = newConfig['apiToken'];
  };

  MCO.getConfig = function() {
    return config;
  };

  MCO.checkCart = function(cart, resolve, reject) {
    var data = {
      cart: cart
    };
    return makeRequest(buildUrl('carts'), data, resolve, reject);
  };

  MCO.checkCartForItem = function(cart, item, resolve, reject) {
    var data = {
      cart: cart,
      item: item
    };

    return makeRequest(buildUrl('carts/item'), data, resolve, reject);
  };

  return MCO;
}


function Limitsify(config) {
  var _this = this;

  this.parseQuery = function(query) {
    if(query == undefined || query == '') {
      return {};
    }

    var pairs = query.split('&');

    var result = {};
    pairs.forEach(function(pair) {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return result;
  };

  this.initConfig = function(config) {
    if(config == undefined || config == '') {
      return { dots: 'yes', space: '0', propagate: false };
    }
    var parsedConfig = this.parseQuery(config);
    if (parsedConfig.propagate) {
      parsedConfig.propagate = (parsedConfig.propagate == 1);
    }

    return parsedConfig;
  };

  _this.states = {
    initial: 0,
    processing: 1,
  };
  _this.api = new LimitsifyAPI().init({apiToken: config['apiToken']}) ;
  _this.alertAdapter = config['theme'];
  _this.cart = config['cart'];
  _this.alertSettings = _this.initConfig(config['settings']);
  _this.propogateClick = _this.alertSettings.propagate || false;
  _this.noInputListeners = _this.alertSettings.notlisten || 0;
  _this.shouldDisableBtns = _this.alertSettings.disablebtns || false;
  _this.requireUpdateEnabled = _this.alertSettings.rue || 1;
  _this.displayStyle = _this.alertSettings.ds || 'inline-block';

  _this.saveAmazonButtonHandler = null;
  _this.saveSplitPartialHandler = null;


  _this.labels = {update_cart: 'Cart Needs to Be Updated'};
  _this.validCart = false;
  _this.applied = false;
  _this.state = _this.states.initial;
  _this.authorized = true;
  _this.shop = null;

  this.cartButtons = function() {
    return ["input[name='checkout']",
        "button[name='checkout']",
        "input[name='goto_pp']",
        "button[name='goto_pp']",
        "input[name='goto_gc']",
        "button[name='goto_gc']",
        "a[href$='/checkout']",
        "button[onclick*='/checkout']",
        "button.additional-checkout-button--apple-pay",
        '.shopify-payment-button',
        '#buy-me-cart',
        '.qimify-minicart-viewcart',
        "#check_subscribe",
        '#slidecarthq footer button',
        'button.cart-checkout-btn',
        'form[action^="/checkout"] [type=submit]',
        '#CartDrawer a[href$="/cart"]',
        '.Cart__Checkout.Button'
      ];
  };

  this.updatableCartButtons = function() { '[name="checkout"],button[name="checkout"]' };

  this.updateCartButtons = function() {
    return ["input[name='update']", "button[name='update']", "a[href$='/update']", "button[onclick*='/update']"];
  };

  this.overAmazonHandler = function() {
    if (typeof amazonPaymentsButtonHandler != 'undefined') {
      amazonPaymentsButtonHandler.execute = function (e) {
        _this.checkCart(function () {
          amazonPaymentsButtonHandler.execute = _this.saveAmazonButtonHandler;
          const btn = document.querySelector('.amazon-payments-pay-button img');
          if(btn != undefined) {
            btn.click();
          }
        });
      }
    }
  };

  this.splitPartialButton = function() { return document.querySelector("button#split-payment.tooltipstered"); };

  this.overridedSplitPartialHandler = function() {
    var button = _this.splitPartialButton();
    if(button) {
      _this.saveSplitPartialHandler = button.onclick;
      button.onclick = function(e) {
        _this.checkCart(function () {
          button.onclick = _this.saveSplitPartialHandler;
          button.click();
        });
        return _this.propogateClick;
      }
    }
  };

  this.disableIfTerms = function () {
    if(typeof(Shopify) != 'undefined' && Shopify.theme != null) {
      if ([113466245284].includes(Shopify.theme.id)) {
        var button = document.querySelector('button[name="checkout"]');
        const terms = document.getElementById("termsCheckbox");
        if(terms != undefined && button != undefined && terms.checked == false) {
          button.setAttribute('disabled', true);
        }
      }
    }
  }

  this.init = function() {
    getShopName();
    var cartButtons = this.cartButtons();
    setInputListener(cartButtons);
    setAmazonListener();
    setPartialPaymentsListner();
    enableButtons(cartButtons);
    this.disableIfTerms();
    setUpdateCartText();
    setChangeCartListener(cartButtons);
    paypalButtonListener();
    setButtonListener(cartButtons);
    setAddToCartListener();
    initialChecking();
    hideBuyNow();
    showLegend();
    initQuantity();
  };

  this.unbindMouseDown = function() {
    if(window.$) {
      window.$('[name=checkout],[name=goto_pp],.amazon-payments-pay-button').off('mousedown');
      window.$('[name=checkout],[name=goto_pp],.amazon-payments-pay-button').off('mousedown touchstart');
      window.$('[name=checkout],[name=goto_pp],.amazon-payments-pay-button').unbind('mousedown touchstart');
    }
  }

  var getShopName = function() {
    if(typeof(Shopify) != 'undefined') {
      _this.shop = Shopify.shop;
    } else {
      _this.shop = window.location.hostname;
    }
  };

  var isDisabled = function(button) {
    if(_this.requireUpdateEnabled == 0) {
      return false;
    }
    return button != undefined && !!button.getAttribute("requireUpdate");
  };


  var setAmazonListener = function() {
    document.addEventListener("DOMContentLoaded", function() {
      if (typeof amazonPaymentsButtonHandler != 'undefined') {
        _this.saveAmazonButtonHandler = amazonPaymentsButtonHandler.execute;
        _this.overAmazonHandler();
      }
    });
  };

  var setPartialPaymentsListner = function() {

    var elem = document.querySelector('form[action^="/cart"]');
    if (elem) {
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          for (var i = 0; i < mutation.addedNodes.length; i++) {
            if (mutation.addedNodes[i].id == "split-payment") {
              _this.overridedSplitPartialHandler();
            }
          }
        });
      });
      observer.observe(elem, {childList: true, subtree: true});
    }
  };

  var initialChecking = function() {
    var path = window.location.pathname;
    const params = window.location.search.substr(1);
    if(path == '/cart' && _this.alertSettings.initcheck != 0 && !params.includes("nav_to_checkout")) {
      _this.checkCart(function () {});
    }
  }


  var showLegend = function() {
    var path = window.location.pathname;
    if(path.search("/product") != -1 && _this.alertSettings.legend == 1) {

      const blocks = document.querySelectorAll('.limitsify-limit-message');
      blocks.forEach(function(block) {
        const configAttr = block.getAttribute('data-config');
        if (configAttr) {
          const config = _this.parseQuery(configAttr);
          const min = config['min'];
          const max = config['max'];
          var str = '<div>';
          if (min > 0) {
            str += 'Minimum Quantity: ' + min + '<br/>';
          }
          if(max > 0) {
            str += 'Maximum Quantity: ' + max;
          }
          str += '</div>';
          block.innerHTML = str;
        }
      });
    }
  }

  var initQuantity = function() {
    const blocks = document.querySelectorAll('.limitsify-select-qnt');
    blocks.forEach(function(block) {
      const configAttr = block.getAttribute('data-config');
      if (configAttr) {
        const config = _this.parseQuery(configAttr);
        const min = parseInt(config['min'] || 1) || 1;
        const max = parseInt(config['max'] || 100);
        const step = parseInt(config['step'] || min);
        var i, L = block.options.length - 1;
        for(i = L; i >= 0; i--) { block.remove(i); }
        for (var i = min; i <= max; i = i + step) {
          var option = document.createElement("option");
          option.text = i;
          option.value=i;
          block.add(option);
        }
      }
    });
  }

  var hideBuyNow = function() {
    var forms = document.querySelectorAll('form[action^="/cart/add"]');
    forms.forEach(function(form) {
      const hasLimit = form.getAttribute('limitsify');
      if(hasLimit == 1) {
        const btn = form.querySelector('.shopify-payment-button');
        btn.style.display = 'none';
      }
    });
  }

  var checkAdditionalCheckoutBtn = function(cart) {
    if(!_this.authorized){
      _this.toggleAdditionalCheckout(true);
      return;
    }
    if(window.__CONSISTENT_CART_SETTINGS) return;
    if(cart.items.length == 0) return;
    _this.api.checkCart(cart, function (resp) {
      _this.toggleAdditionalCheckout(resp.valid);
    }, function (err) {
      // app was removed
      _this.toggleAdditionalCheckout(true);
      if (err != null && err.status == 401) {
        _this.authorized = false;
      }
      callback();
    });
  };

  var handleRequest = function(callback) {
    (function() {
      var origOpen = XMLHttpRequest.prototype.open;
      XMLHttpRequest.prototype.open = function() {
        this.addEventListener('load', function() {
          callback(this);
        });
        origOpen.apply(this, arguments);
      };
    })();
  }

  var setChangeCartListener = function(cartButtons) {
    handleRequest(function(response) {
      if (response.responseURL.search(/\/cart(\/update|\/change|)\.js/) > 0) {

        const cart = (response.responseType == 'json') ? response.response : JSON.parse(response.response);
        _this.cart = cart;
        setTimeout(function () {
          if(!_this.shouldDisableBtns) {
            enableButtons(cartButtons);
          }
          _this.disableIfTerms();
        }, 300);
        _this.overAmazonHandler();
      }
      // var cartUrl = window.location.host + '/cart';
      // var regex = new RegExp(cartUrl + '(?!\/|.js)', 'g');
      // var found = response.responseURL.match(regex);
      // if (found) {
      //   setTimeout(function () {
      //     checkAdditionalCheckoutBtn(_this.cart);
      //   }, 500);
      // }
    })
  };

  var paypalButtonListener = function() {
    var head = document.getElementsByTagName("body")[0];
    _this.toggleAdditionalCheckout(false);
    if(head) {
      head.addEventListener("load", function(event) {
        var src = event.target.getAttribute("src");
        if (src != undefined && src.search("paypal.com") > 0 && src.search("button") > 0)  {
          setTimeout(function () {
            checkAdditionalCheckoutBtn(_this.cart);
          }, 180);
        }
      }, true);
    }
  };

  var setUpdateCartText = function() {
    var updateCartButtons = _this.updateCartButtons();
    if (!_this.alertSettings.cart_update_text) {
      saveButtonLabels('update_cart', updateCartButtons)
    } else {
      _this.labels['update_cart'] = _this.alertSettings.cart_update_text.replace(/\+/g, ' ');
    }
  };

  var saveButtonLabels = function(buttonType, buttons) {
    buttons.forEach(function(button) {
      const elem = document.querySelector(button);
      if (elem != undefined) {
        var text = elem.innerText || elem.value;
        if (!!text) {
          _this.labels[buttonType] = text;
        }
      }
    })
  };

  var enableButtons = function(buttons) {
    if((document.querySelector('.ot-delivery-date') != undefined) &&
      ((document.querySelector('#ot-datepicker') != undefined &&
        document.querySelector('#ot-datepicker').value == "") ||
      (document.querySelector('#ot-datepicker') == undefined && document.querySelector('.ot-delivery-date-notice-text') == undefined)
      )) return;

    buttons.forEach(function(selector) {
      const btns = document.querySelectorAll(selector);
      btns.forEach(function(btn) {
        btn.removeAttribute("requireUpdate");
        btn.removeAttribute("disabled");
      });
    })
  };

  var findButtonTarget = function(clickedTarget, btns) {
    if ($(clickedTarget).is(btns)) {
      return clickedTarget;
    }
    var parentTarget = $(btns).has(clickedTarget);
    if (parentTarget.length) {
      return parentTarget[0];
    }
    return null;
  };

  var isHandClick = function(event) {
    return event.hasOwnProperty('originalEvent') && (event.target.isLimitsifyClick === undefined);
  };

  var isHandClick2 = function(event) {
    return (event.target.isLimitsifyClick === undefined);
  };

  var showNeedToUpdateAlert = function() {
    _this.alertAdapter.showAlertCallback({}, '', [_this.labels['update_cart']]);
  }

  var stopPropagation = function(event) {
    if(!_this.propogateClick) {
      event.stopImmediatePropagation();
    }
    return _this.propogateClick;
  }

  var skipValidation = function() {
    const params = window.location.search.substr(1) || "";
    return params.includes("nav_to_checkout");
  }

  var setButtonListener = function(buttons) {
    var btns = buttons.join(',');
    $("body,.action-links").on("click", function(event) {
      var clickedTarget = event.target;
      var mainTarget = findButtonTarget(clickedTarget,btns);
      if(skipValidation()) {
        return true;
      }
      if (_this.authorized && mainTarget) {
        var disabled = isDisabled(mainTarget);
        if (disabled) {
          showNeedToUpdateAlert();
          return stopPropagation(event);
        } else {

          // if it is first click and click is initiated by man
          if((_this.state === _this.states.initial) && isHandClick(event)) {

            disableButton(mainTarget);
            _this.checkCart(function(){
              _this._propogateClick(event);
            });
            // it is for shops which use other plugin with click events
            return stopPropagation(event);
          }

          if (_this.state === _this.states.processing) {
            _this.state = _this.states.initial;
            event.target.isLimitsifyClick = undefined;
            if (_this.validCart) {
              _this.validCart = false;
              return true;
            } else {
              return stopPropagation(event);
            }
          }
          return true;
        }
      } else {
        return true;
      }
    });
  };

  var _setAddToCartClickListener = function(btn, item) {
    btn.addEventListener("click", function(event) {
      if (_this.authorized) {
        if((_this.state === _this.states.initial)) {
          btn.setAttribute("requireUpdate", true);
          _this.checkCartForItem(item, function(){
            _this._propogateClick(event);
          });
          // it is for shops which use other plugin with click events
          if(_this.propogateClick) {
            return true;
          } else {

            event.preventDefault();
            event.stopImmediatePropagation();
            return false;
          }
        }

        if (_this.state === _this.states.processing) {
          _this.state = _this.states.initial;
          event.target.isLimitsifyClick = undefined;
          if (_this.validCart) {
            _this.validCart = false;
            return true;
          } else {
            return _this.propogateClick;
          }
        }
        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
      } else {
        return true;
      }
    });
  };

  var getThemeId = function() {
    if(typeof(Shopify) != 'undefined' && Shopify.theme != null)
      return Shopify.theme.id;
    else return 0;
  };

  var setAddToCartListener = function() {
    const extended = _this.alertSettings.extended == 1;
    const satcl = _this.alertSettings.satcl == 1;
    if (!extended) { return; }
    const tt = (getThemeId() == 117030453418) ? 500 : 0;
    const forms = document.querySelectorAll('form[action^="/cart/add"]');
    setTimeout(function(){
    forms.forEach(function(form) {
      const submits = form.querySelectorAll("[type=submit]");
      submits.forEach(function(submit) {
        submit.addEventListener("click", function(event) {
        if (_this.authorized) {
          if((_this.state === _this.states.initial)) {
            var productId = form.getAttribute('data-productId');
            var vendor = null;
            var type = null;
            if(productId == undefined) {
               productId = form.getAttribute('data-product-id');
            }
            if(productId == undefined && window.meta && window.meta.product) {
              productId = window.meta.product.id;
            }
            if(window.meta && window.meta.product) {
              vendor = window.meta.product.vendor;
              type = window.meta.product.type;
            }


            const sku = form.getAttribute('data-sku');
            const titleItem = document.querySelector('.product-title,.product_name');
            const title = (titleItem == undefined) ? null : titleItem.innerText;

            var quantityInput = form.querySelector('input[name="quantity"]');
            if(quantityInput == undefined){
              quantityInput = form.querySelector('select[name="quantity"]');
            }
            if(quantityInput == undefined){
              quantityInput = document.querySelector('input[name="quantity"]');
            }
            const quantity = quantityInput != undefined ? quantityInput.value : 0;
            var item = { product_id: productId, quantity: quantity, sku: sku, title: title, vendor: vendor, product_type: type };
            submit.setAttribute("requireUpdate", true);
            _this.checkCartForItem(item, function(){
              _this._propogateClick(event);
            });
            // it is for shops which use other plugin with click events
            if(_this.propogateClick) {
              return true;
            } else {
              if(satcl) {
                if(!_this.validCart) {
                  event.preventDefault();
                  event.stopImmediatePropagation();
                  return false;
                } else {
                  return true;
                }
              }

              event.preventDefault();
              event.stopImmediatePropagation();
              return false;
            }
          }

          if (_this.state === _this.states.processing) {
            _this.state = _this.states.initial;
            event.target.isLimitsifyClick = undefined;
            if (_this.validCart) {
              _this.validCart = false;
              return true;
            } else {
              return _this.propogateClick;
            }
          }
          event.preventDefault();
          event.stopImmediatePropagation();
          return false;
        } else {
          return true;
        }
      });
      });

    });
    },tt);

    return;

    const isProductPage = ((window.location.pathname || "").search('/products')) > -1;
    if(isProductPage) {
      const addToCartButton = document.querySelector('button[name="add"].multi_variantcart,[data-action^="add-to-cart"]');
      if(addToCartButton != undefined && window.meta != undefined && window.meta.product != undefined) {
        const item = { product_id: window.meta.product.id, quantity: 0, sku: '', title: '', vendor: window.meta.product.vendor };
        _setAddToCartClickListener(addToCartButton, item);
      }
    }

  }


  var setInputListener = function(buttons) {
      if(_this.noInputListeners == "1") return;
      const body = document.querySelector('body');
      if(body) {
        const listener = function(event) {
          const classList = event.target.classList;
          const name = event.target.name || '';
          if(classList.contains("ajaxcart__qty-num") || name.includes("updates[")) {
            needToBeUpdating(buttons);
          }
        }
        body.addEventListener('change', listener);
        body.addEventListener('input', listener);
        body.addEventListener('click', function(event){
          const classList = event.target.classList;
          var changed = false;
          [
            "increase",
            "decrease",
            "quantity-add",
            "quantity-remove",
            "plus-minus",
            "js-qty__adjust",
            "cart__remove",
            "cart-item-decrease",
            "cart-item-increase",
            "js--qty-adjuster",
            "ajaxcart__qty-adjust",
            "ajaxifyCart--qty-adjuster",
            "js-change-quantity",
            "inputCounter-up",
            "inputCounter-down"].forEach(function(selector){
              if(classList.contains(selector)) {
                changed = true;
              }
            });
          if(changed) {
            needToBeUpdating(buttons);
          }
        });
      }
  };

  var disableButton = function(button) {
    if(button != undefined) {
      button.setAttribute("requireUpdate", true);
    }
  };

  var needToBeUpdatingExtra = function() {
    if(typeof(Shopify) != 'undefined' && Shopify.theme != null) {
      if ([44043763770, 153603909, 94512742532, 48879427, 12624527405, 118765387962].includes(Shopify.theme.id)) {
        _this.toggleAdditionalCheckout(false);
      }
    }
    const toggle = _this.alertSettings.toggleckt == 1;
    if(toggle) {
      _this.toggleAdditionalCheckout(false);
    }
  };

  var needToBeUpdatingDisableButtons = function() {
    if(typeof(Shopify) != 'undefined' && _this.applied && Shopify.theme != null) {
      if ([169083594].includes(Shopify.theme.id)) {
        const updateCartElement = document.querySelector("input[name='update']");
        _this.cartButtons().forEach(function(selector){
          var button = document.querySelector(selector);
          if(button != undefined) {
            button.disabled = true;
            if(updateCartElement != undefined) {
              button.style.display='none';
            }
          }
        });

        if(updateCartElement != undefined) {
          updateCartElement.style.display='inline-block';
        }
      }
    }
  };

  var needToBeUpdating = function(buttons) {
    buttons.forEach(function(selector){
      const btns = document.querySelectorAll(selector);
      btns.forEach(function(btn) {
        disableButton(btn);
      });
    });
    _this.unbindMouseDown();
    if (typeof amazonPaymentsButtonHandler != 'undefined') {
      amazonPaymentsButtonHandler.execute = function () {
        showNeedToUpdateAlert();
      };
    }
    var spButton = _this.splitPartialButton();
    if (spButton) {
      spButton.onclick = function() {
        showNeedToUpdateAlert();
        return _this.propogateClick;
      };
    }
    needToBeUpdatingExtra();
    needToBeUpdatingDisableButtons(buttons);
  };

  this.init();

};

Limitsify.prototype = {
  enableButtons: function() {
    if((document.querySelector('.ot-delivery-date') != undefined) &&
      ((document.querySelector('#ot-datepicker') != undefined &&
        document.querySelector('#ot-datepicker').value == "") ||
      (document.querySelector('#ot-datepicker') == undefined && document.querySelector('.ot-delivery-date-notice-text') == undefined)
      )) return;
    var _this = this;
    var btns = _this.cartButtons();
    btns.forEach(function(selector) {
      const buttons = document.querySelectorAll(selector);
      buttons.forEach(function(btn) {
        btn.removeAttribute("requireUpdate");
        btn.removeAttribute("disabled");
      })
    })
  },
  disableButtons: function() {
    if(this.shouldDisableBtns){
      ['#cart_submit', "input[name='checkout']", '.Cart__Checkout.Button', 'input.cart__checkout'].forEach(function(button) {
        const btn = document.querySelector(button) ;
        if(btn != undefined) {
          btn.disabled = true;
        }
      });
    }
  },
  toggleAdditionalCheckout: function(valid) {
    var isMobile = /iPhone|iPad|iPod|Android|Opera Mini|IEMobile/i.test(navigator.userAgent);
    var additionalCheckoutButton = '.additional-checkout,.additional-checkout-button,.additional-checkout-buttons,.additional_checkout_buttons,.cart__additional_checkout,#additional-checkout-buttons,#dynamic-checkout-cart,.ajax-cart__buttons-additional';

    var display = this.displayStyle;
    if(valid) {
      if(!isMobile) { additionalCheckoutButton += ':not(.additional-checkout-button--apple-pay)'; }
    } else {
      display = 'none';
    }

    const btns = document.querySelectorAll(additionalCheckoutButton);
    btns.forEach(function(btn){
      btn.style.display = display;
    })
  },
  _propogateClick: function(event) {
      this.state = this.states.processing;
      this.validCart = true;
      event.target.isLimitsifyClick = true;
      if(typeof(Shopify) != 'undefined' && Shopify.theme != null && [83490340910, 83244777518, 113050288294, 117769109695, 117825208505, 22963126308].includes(Shopify.theme.id)){
        const isSubscriptionBtn = event.target.classList.contains('button--subscriptions');
        if(isSubscriptionBtn) {
          event.target.click();
        } else {
          var evt = document.createEvent('MouseEvents');
          evt.initEvent('click', true, false);
          event.target.dispatchEvent(evt);
        }
      } else {
        event.target.click();
      }
  },
  _checkCart: function(cart, callback) {
    var _this = this;
    _this.api.checkCart(cart, function (resp) {
      _this.enableButtons();
      _this.toggleAdditionalCheckout(resp.valid);
      _this.disableIfTerms();
      _this.applied = resp.applied;
      if (resp.valid) {
        callback();
      } else {
        _this.validCart = false;
        window.foxdrive = undefined;
        _this.alertAdapter.showAlertCallback(_this.alertSettings, resp.header, resp.messages);
        _this.disableButtons();
      }
    }, function (err) {
      _this.enableButtons();

      // app was removed
      if (err != null && err.status == 401) {
        _this.authorized = false;
      }
      callback();
    });
  },
  checkCart: function(callback) {
    var _this = this;
    var attrs = _this.getAttributes();
    _this.getShopifyCart(function(cart){
      cart.attrs = attrs;
      if(cart.items.length > 0) {
        _this._checkCart(cart, callback);
      }
    });
  },
  checkCartForItem: function(item, callback) {
    var _this = this;
    _this.getShopifyCart(function(cart){

      _this.api.checkCartForItem(cart, item, function (resp) {
        _this.enableButtons();
        if (resp.valid) {
          callback();
        } else {
          _this.validCart = false;
          _this.alertAdapter.showAlertCallback(_this.alertSettings, resp.header, resp.messages);
        }
      }, function (err) {
        _this.enableButtons();

        // app was removed
        if (err != null && err.status == 401) {
          _this.authorized = false;
        }
        callback();
      });
    });
  },
  getShopifyCart: function(callback) {
    var _this = this;
    if(typeof(Shopify) != 'undefined') {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', '/cart.js?limitsify=1',[false]);
      xhr.responseType = 'json';
      xhr.send();
      xhr.onload = function() {
        if (xhr.status != 200) {
          callback(_this.cart);
        } else {
          callback(xhr.response);
        }
      };
    } else {
      callback(_this.cart);
    }
  },

  getAttributes: function() {
    var attrs = {};
    var forms = document.querySelectorAll('form[action^="/cart"],form[action^="/checkout"],form[action$="/cart"],form[action-url$="/cart"]');
    forms.forEach(function(form) {
      var delivery_date = null;
      var input = form.querySelector('input[name="attributes[streamthing_delivery_date]"]');
      if(!input){
        input = form.querySelector('input[name="attributes[collectionDatePicker]"]');
      }
      if(!input){
        input = form.querySelector('input[name="attributes[_formattedDate]"]');
      }
      if(!input){
        input = form.querySelector('input[name="attributes[Pickup_date]"]');
      }
      if(!input){
        input = form.querySelector('input[name="zpDate"]');
      }
      if(!input){
        input = form.querySelector('input[name="attributes[Pickup-Date]"]');
      }
      if(!input){
        input = form.querySelector('input[name="attributes[Delivery-Date]"]');
      }
      if(!input){
        input = form.querySelector('input[name="attributes[Delivery Date]"]');
      }
      if(!input){
        input = form.querySelector('input[name="attributes[Order Due Date]"]');
      }
      if(!input){
        input = form.querySelector('input[name="name[Delivery date]"]');
      }
      if(!input){
        input = form.querySelector('input[name="name[date]"]');
      }
      if(input) delivery_date = input.value;
      if(delivery_date) attrs.delivery_date = delivery_date;
    });
    return attrs;
  }
};


Limitsify.DefaultAdapter = function() {

  this.showAlertCallback = function(settings, header, messages) {
    if (header == undefined) { header = ''; }
    var dot = '';
    if (settings.dots == 'yes') { dot = '\u25C6 '; }

    var message = header + '\n' + dot + messages.join('\n' + dot);
    alert(message);
  }
};

Limitsify.AlertifyLogAdapter = function() {

  this.showAlertCallback = function(settings, header, messages) {
    if(typeof(alertify) != 'undefined') {
      messages.forEach(function(message) {
        var m = header + '<br/>' + message;
        alertify.log(m);
      })
    }
    else {
      (new Limitsify.DefaultAdapter).showAlertCallback(settings, header, messages);
    }
  };

};

Limitsify.Alertify2Adapter = function() {

  this.showAlertCallback = function(settings, header, messages) {
    if (header == undefined) { header = ''; }
    if(typeof(alertify) != 'undefined') {
      var dot = '';
      if (settings.dots == 'yes') { dot = '\u25C6 '; }
      var space = settings.space || 0;

      var message = '<div class="alert-msg-box"><div class="alert-msg-header" style="margin-bottom:'+ space +'px">' + header + '</div>';
      messages.forEach(function(msg) {
        message += '<div style="margin-bottom:'+ space +'px" class="alert-msg">' + dot + msg + '</div>';
      });
      message += '</div>';

      alertify.alert(message);
    } else {
      (new Limitsify.DefaultAdapter).showAlertCallback(settings, header, messages);
    }
  };

};

Limitsify.AlertifyAdapter = function() {

  this.showAlertCallback = function(settings, header, messages) {
    if(typeof(alertify) != 'undefined') {
      (new Limitsify.Alertify2Adapter).showAlertCallback(settings, header, messages);
    } else {
      (new Limitsify.DefaultAdapter).showAlertCallback(settings, header, messages);
    }
  }
}

Limitsify.AlertifyBootstrapAdapter = function() {

  this.showAlertCallback = function(settings, header, messages) {
    if(typeof(alertify) != 'undefined') {
      (new Limitsify.Alertify2Adapter).showAlertCallback(settings, header, messages);
    } else {
      (new Limitsify.DefaultAdapter).showAlertCallback(settings, header, messages);
    }
  }
}


module.exports = Limitsify;
